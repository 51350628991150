import { useCallback, useContext } from "react";
import { SideContext } from "../side/types";
import { IPosition } from "../types";
import * as Pixi from 'pixi.js';
import { Orientations } from "../../markers/types";
import { LineMarkerProps } from "../../markers/line/types";
import { useText } from "../../markers/base/text/hooks";
import { ILengthMarkerProps } from "../../markers/length/types";
import { ColorContext } from "../../color/types";
import { ICableBaseAnnotationProps } from "./annotation/types";
import { MarkerSettingsContext } from "../../../workspace/store/types";
import { convertTo, toLengthString, Units } from "../../../workspace/assembly/length/types";
import { getTrunkHeight } from "../breakout/connector-furcation/hooks";
import { PIGTAIL_NO_LEG } from "../../../workspace/assembly/connector/types";

export const normalTrunkHeight = 8;
export const trunkWidth = 90;

export const jacketWidth = 30;
const jacketHeight = 12;

const normalShieldWidth = 24;
const normalShieldHeight = 8;

const normalLineThickness = 2;
const lineWidth = 8;
const normalLineHeight = 24;

const normalBorderThickness = 1.0;

export const textOffset = 12;

export const cableAnchorPoint = { x: (trunkWidth + jacketWidth), y: 0 }
export const useCableBase = () => {
    const { cableColor: { jacketColor, centerLineColor, shieldColor, outlineColor, furcationColor } } = useContext(ColorContext);
    const { m, breakouts, hasManyConnectors, flipsideConnectorType} = useContext(SideContext);
    const { showCableBaseReferences, showTransitions, showABMarkers } = useContext(MarkerSettingsContext);
    const connectorType = breakouts[0].furcation.groups[0].connectors[0].type;
    const isPigtailWithNoLeg = connectorType === PIGTAIL_NO_LEG;
    const removeStartDrawing = isPigtailWithNoLeg || flipsideConnectorType === PIGTAIL_NO_LEG;

    const trunkHeight = showTransitions ? normalTrunkHeight : getTrunkHeight(connectorType);
    const borderThickness = showTransitions ? normalBorderThickness : 0.5;
    const shieldHeight = showTransitions ? normalShieldHeight : trunkHeight;
    const shieldWidth = showTransitions ? normalShieldWidth : 8;
    const lineThickness = showTransitions ? normalLineThickness : 1;
    const lineHeight = showTransitions ? normalLineHeight : 8;


    const showReferences = showCableBaseReferences && showABMarkers;
    const drawJacket = useCallback((g: Pixi.Graphics) => {
        g.clear();
        const jacketStart = removeStartDrawing ? 0 : m === 1 ? 5.35 : -2.7;
        const jacketEnd = removeStartDrawing ? 0 : m === 1 ? 2.7 : -5.35;
        const path: Pixi.Point[] = [
            new Pixi.Point(jacketStart, -trunkHeight / 2),
            new Pixi.Point(m * (trunkWidth + jacketWidth), -trunkHeight / 2),
            new Pixi.Point(m * (trunkWidth + jacketWidth), trunkHeight / 2),
            new Pixi.Point(jacketEnd, trunkHeight / 2)
        ];
        g.beginFill(jacketColor);
        g.drawPolygon(path);
        g.endFill();

        g.lineStyle(borderThickness, outlineColor);
        g.moveTo(path[0].x, path[0].y - borderThickness / 2).lineTo(path[1].x, path[1].y - borderThickness / 2);
        g.moveTo(path[2].x, path[2].y + borderThickness / 2).lineTo(path[3].x, path[3].y + borderThickness / 2);
    }, [removeStartDrawing, m, trunkHeight, jacketColor, borderThickness, outlineColor]);

    const jacketStart = m * trunkWidth;

    const drawFurcation = useCallback((g: Pixi.Graphics) => {

        g.clear();
        const path: Pixi.Point[] = [
            new Pixi.Point(jacketStart, -jacketHeight / 2),
            new Pixi.Point(m * (jacketWidth + trunkWidth), -jacketHeight / 2),
            new Pixi.Point(m * (jacketWidth + trunkWidth), jacketHeight / 2),
            new Pixi.Point(jacketStart, jacketHeight / 2)
        ];
        g.lineStyle(borderThickness, outlineColor);
        g.beginFill(furcationColor);
        g.drawPolygon(path);
        g.endFill();
    }, [jacketStart, m, outlineColor, furcationColor, borderThickness]);

    const shieldStart = m * 12;
    const drawShield = useCallback((g: Pixi.Graphics) => {
        g.clear();
        const path: Pixi.Point[] = [
            new Pixi.Point(shieldStart, -shieldHeight / 2),
            new Pixi.Point(shieldStart + m * shieldWidth, -shieldHeight / 2),
            new Pixi.Point(shieldStart + m * shieldWidth, shieldHeight / 2),
            new Pixi.Point(shieldStart, shieldHeight / 2)
        ];
        g.lineStyle(borderThickness, outlineColor);
        g.beginFill(shieldColor);
        g.drawPolygon(path);
        g.endFill();
    }, [shieldStart, m, outlineColor, shieldColor, borderThickness, shieldHeight, shieldWidth]);

    const drawCableStart = useCallback((g: Pixi.Graphics) => {
        g.clear();
        g.moveTo(0, m * lineHeight / 2);
        g.lineStyle(lineThickness, centerLineColor);
        g.lineTo(m * lineWidth, -m * lineHeight / 2);
    }, [m, centerLineColor, lineHeight, lineThickness]);

    const lengthMarkerProps = useLengthMarker(shieldStart, shieldWidth, jacketStart);
    const cableBaseAnnotationProps: ICableBaseAnnotationProps = { shieldStart: 12, furcationStart: (jacketWidth * 0.5 + trunkWidth) * 0.5 }

    return { showReferences, showTransitions, hasManyConnectors, showABMarkers, lengthMarkerProps, drawJacket, drawFurcation, drawShield, drawCableStart, cableBaseAnnotationProps, isPigtailWithNoLeg, removeStartDrawing }
}

const useLengthMarker = (shieldStart: number, shieldWidth: number, jacketStart: number): ILengthMarkerProps => {
    const { m, unit } = useContext(SideContext);
    const lineThickness = 1; 
    const coordinateX = m > 0 ? (shieldStart + shieldWidth + 0.6): jacketStart + 0.75;
    const delimiterLength = 12;
    const coordinateY = -(delimiterLength + 8);
    const length = m > 0 ? jacketStart - shieldWidth - shieldStart - 0.4 : shieldStart - shieldWidth - jacketStart - 0.30;
    const text = toLengthString(convertTo({ value: 150, unit: Units.Millimeter }, unit));
    const toleranceValue = unit === Units.Millimeter ? 15 : 0.59;
    const lineMarkerProps: LineMarkerProps = {
        coordinate: { x: coordinateX, y: coordinateY },
        orientation: Orientations.Horizontal,
        length,
        text,
        thickness: lineThickness,
        color: 0x000000,
        alpha: 1,
        startDelimiterLength: delimiterLength,
        endDelimiterLength: delimiterLength,
        toleranceProps: { tolerance: { min: -toleranceValue, max: toleranceValue } },
        isTrunkMarker: true
    };

    const lengthText = m > 0 ? "B0" : "A0";
    const { textStyle, textSize } = useText({ text: lengthText, fill: 0x000000, fontSize: 10 });
    const textX = coordinateX + length * 0.5 - textSize.width;
    const textY = coordinateY - textSize.height - textOffset;
    const lengthPosition: IPosition = { x: textX, y: textY };
    const textProps = {
        text: lengthText,
        style: textStyle,
        position: lengthPosition,
        resolution: 10,
        scale: 1
    }

    return { showMarker: true, lineMarkerProps, textProps };
}