import { PayloadAction } from "@reduxjs/toolkit";
import { convertTo, Length, Unit } from "../../../assembly/length/types";
import { CableEnvironment, CableType, FiberType, OverallLengthType, Shape } from "../../../assembly/types";
import { IGeneralSetupState } from "./types";

export const setShapeAction = (state: IGeneralSetupState, action: PayloadAction<Shape>) => {
    state.shape = action.payload;
};

export const setFiberCountAction = (state: IGeneralSetupState, action: PayloadAction<number>) => {
    state.fiberCount = action.payload;
};

export const setCableTypeAction = (state: IGeneralSetupState, action: PayloadAction<CableType>) => {
    state.type = action.payload;
};

export const setFiberTypeAction = (state: IGeneralSetupState, action: PayloadAction<FiberType>) => {
    state.fiberType = action.payload;
};

export const setCableEnvironmentAction = (state: IGeneralSetupState, action: PayloadAction<CableEnvironment>) => {
    state.environment = action.payload;
};

export const setOverallLengthAction = (state: IGeneralSetupState, action: PayloadAction<Length>) => {
    state.overallLength = action.payload;
};

export const setLengthAAction = (state: IGeneralSetupState, action: PayloadAction<Length>) => {
    state.lengthA = action.payload;
};

export const setLengthBAction = (state: IGeneralSetupState, action: PayloadAction<Length>) => {
    state.lengthB = action.payload;
};

export const setFlameRatingAction = (state: IGeneralSetupState, action: PayloadAction<string>) => {
    state.flameRating = action.payload;
};

export const setOverallLengthTypeAction = (state: IGeneralSetupState, action: PayloadAction<OverallLengthType>) => {
    state.overallLengthType = action.payload;
}

export const setPullingGripAction = (state: IGeneralSetupState, action: PayloadAction<string>) => {
    state.pullingGrip = action.payload;
};

export const setFurcationOuterDiameter = (state: IGeneralSetupState, action: PayloadAction<string>) => {
    state.furcationOuterDiameter = action.payload;
};

export const setGeneralSetupStateAction = (state: IGeneralSetupState, action: PayloadAction<Partial<IGeneralSetupState>>) => {
    const newState = {...action.payload};

    if (newState.environment !== undefined) state.environment = newState.environment;
    if (newState.fiberCount !== undefined) state.fiberCount = newState.fiberCount;
    if (newState.lengthA !== undefined) state.lengthA = convertTo(newState.lengthA, state.lengthA.unit);
    if (newState.lengthB !== undefined) state.lengthB = convertTo(newState.lengthB, state.lengthB.unit);
    if (newState.overallLength !== undefined) state.overallLength = convertTo(newState.overallLength, state.overallLength.unit);
    if (newState.shape !== undefined) state.shape = newState.shape;
    if (newState.type !== undefined) state.type = newState.type;
    if (newState.fiberType !== undefined) state.fiberType = newState.fiberType;
    if (newState.flameRating !== undefined) state.flameRating = newState.flameRating;
    if (newState.pullingGrip !== undefined) state.pullingGrip = newState.pullingGrip;
    if (newState.furcationOuterDiameter !== undefined) state.furcationOuterDiameter = newState.furcationOuterDiameter;
};

export const convertLengthUnitsAction = (state: IGeneralSetupState, action: PayloadAction<{ unit: Unit, secondaryUnit: Unit }>) => {
    const { unit, secondaryUnit } = action.payload;
    state.overallLength = convertTo(state.overallLength, secondaryUnit)
    state.lengthA = convertTo(state.lengthA, unit)
    state.lengthB = convertTo(state.lengthB, unit)
};