import { IAnnotationContext, initialAnnotationContext } from "../../workspace/annotation/store/types"
import { IBreakout, IConnector, IConnectorGroup, Sides } from "../../workspace/assembly/breakout/types"
import { ConnectorTypes } from "../../workspace/assembly/connector/types"
import { Length, Unit, Units } from "../../workspace/assembly/length/types"
import { IAssembly, IAssemblySide } from "../../workspace/assembly/types"
import { IMarkerSettings, initialMarkerSettings } from "../../workspace/store/types"
import { IColorContext, initialColorContext } from "../color/types"
import { IHighlightedConnector } from "./breakout/connector-furcation/highlight/types"

export interface IPosition { 
    x: number,
    y: number
}

export interface ISize {
    width: number,
    height: number
}

export interface IName {
    name: string,
}

export type CableProps = {
    assembly: Partial<IAssembly>;
    markerSettings: IMarkerSettings;
    annotationContext: IAnnotationContext;
    colorContext: IColorContext;
    highlightContext: IHighlightedConnector[];
    unit: Unit;
}

export const initialLength: Length = { value: 0, unit: "mm" }

export const initialConnector: IConnector = {
    position: 1,
    type: ConnectorTypes.ConnLC.type
}

const initialConnectorGroup: IConnectorGroup = {
    connectors: [{...initialConnector}],
    position: 1,
    length: {...initialLength}
}

const initialSideABreakout: IBreakout = {
    position: 1,
    side: Sides.SideA,
    trunk: { length: { value: 200, unit: "mm"}},
    furcation: {
        groups: [
            { ...initialConnectorGroup, position: 1, connectors: [{...initialConnector, position: 1}],  },
        ]
    }
}

const initialSideBBreakout: IBreakout = {
    position: 2,
    side: Sides.SideB,
    trunk: { length: { value: 200, unit: "mm"}},
    furcation: {
        groups: [
            { ...initialConnectorGroup, position: 1, connectors: [{...initialConnector, position: 1}],  },
        ]
    }
}

const initialAssemblySideA: IAssemblySide = {
    breakouts: [ 
        {...initialSideABreakout, position: 1},
    ]
}

const initialAssemblySideB: IAssemblySide = {
    breakouts: [
        {...initialSideBBreakout, position: 2},
    ]
}

export const initialCableProps: CableProps = {
    assembly: {
        sideA: initialAssemblySideA,
        sideB: initialAssemblySideB,
        assemblyInfo: {
            overallLengthType: "tiptotip",
        }
    },
    markerSettings: initialMarkerSettings,
    annotationContext: initialAnnotationContext,
    colorContext: initialColorContext,
    highlightContext: [],
    unit: Units.Millimeter
}

export const CABContainer = "cab-container";
export const EndDesignationText = "end-designation-text";