import { _ReactPixi } from '@inlet/react-pixi';
import { convertTo, Length, toLengthString, Unit } from '../../../workspace/assembly/length/types';
import { LineMarkerProps } from '../line/types';

export const ToleranceMarkerContainer = "tolerance-marker-container";

export interface ITolerance {
    min: number;
    max: number;
}

export interface ICustomTolerances {
    cableType?: string;
    legTolerance: ITolerance;
    legPrimeTolerance: ITolerance;
    legLabelTolerance: ITolerance;
}

export const defaultTolerances = {
    inch: { min: 0, max: 6 } as ITolerance,
    millimeter: { min: 0, max: 152 } as ITolerance
}

export const defaultLabelTolerances = {
    inch: { min: 0, max: 0.8 } as ITolerance,
    millimeter: { min: 0, max: 20 } as ITolerance
}

export const tolerances = {
    inch: { min: 0, max: 3 } as ITolerance,
    millimeter: { min: 0, max: 76 } as ITolerance
}

export const defaultMinToleranceValues = [
    tolerances.millimeter.min,
    tolerances.inch.min,
    defaultTolerances.millimeter.min,
    defaultTolerances.inch.min,
]

export const defaultMaxToleranceValues = [
    tolerances.millimeter.max,
    tolerances.inch.max,
    defaultTolerances.millimeter.max,
    defaultTolerances.inch.max,
]

export const defaultLabelMinToleranceValues = [
    defaultLabelTolerances.millimeter.min,
    defaultLabelTolerances.inch.min,
]

export const defaultLabelMaxToleranceValues = [
    defaultLabelTolerances.millimeter.max,
    defaultLabelTolerances.inch.max,
]

export const defaultToleranceValues = [
    ...defaultMinToleranceValues, 
    ...defaultMaxToleranceValues, 
];

export const defaultLabelToleranceValues = [
    ...defaultLabelMinToleranceValues,
    ...defaultLabelMaxToleranceValues,
]

export interface IToleranceProps {
    tolerance: ITolerance;
    minTextColor?: number;
    maxTextColor?: number;
}

export type IToleranceMarkerProps = IToleranceProps & {
    textProps: _ReactPixi.IText;
}

export interface ILabelToleranceProps {
    toleranceProps: IToleranceMarkerProps;
    leftLineMarkerProps: LineMarkerProps;
    rightLineMarkerProps: LineMarkerProps;
    isPigtail: boolean;
}

export const convertToleranceUnits = (tolerance: ITolerance, startUnit: Unit, targetUnit: Unit) => {
    let minLength: Length = { value: tolerance.min, unit: startUnit };
    let maxLength: Length = { value: tolerance.max, unit: startUnit };

    minLength = convertTo(minLength, targetUnit);
    minLength.unit = targetUnit;
    minLength.value = parseFloat(toLengthString(minLength));

    maxLength = convertTo(maxLength, targetUnit);
    maxLength.unit = targetUnit;
    maxLength.value = parseFloat(toLengthString(maxLength));

    return { min: minLength.value, max: maxLength.value }
}