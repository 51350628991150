import React from 'react';
import { Container, Graphics } from '@inlet/react-pixi';
import { useCableBase } from './hooks';
import { CableBaseContainer, CableBaseFurcation, CableBaseJacket, CableBaseShield, CableBaseStart } from './types';
import { LengthMarker } from '../../markers/length/LengthMarker';
import { CableBaseAnnotation } from './annotation/CableBaseAnnotation';

export const CableBase: React.FC = () => {
    const { showReferences, showTransitions, hasManyConnectors, lengthMarkerProps, drawJacket, drawFurcation, drawShield, drawCableStart, cableBaseAnnotationProps, isPigtailWithNoLeg, removeStartDrawing } = useCableBase();
    return (
        <Container name={CableBaseContainer}>
            { showReferences && !isPigtailWithNoLeg && <LengthMarker {...lengthMarkerProps} /> }
            <Graphics name={CableBaseJacket} draw={drawJacket} />
            { (showTransitions || hasManyConnectors) && !isPigtailWithNoLeg && <Graphics name={CableBaseFurcation} draw={drawFurcation} />}
            { !isPigtailWithNoLeg && <Graphics name={CableBaseShield} draw={drawShield} />}
            { !removeStartDrawing && <Graphics name={CableBaseStart} draw={drawCableStart} />}        
            <CableBaseAnnotation {...cableBaseAnnotationProps} />
        </Container>
    )
}