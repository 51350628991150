import { convertTo, ILengthFieldProps, toLengthString, Unit, Units } from "./types"
import { useReducer, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../localization/types";
import { setHelperText, setIsValid, setStringValue, TextFieldReducer, updateStringValue } from "../../../ui/input/store/reducer";
import { initialTextFieldState } from "../../../ui/input/store/types";
import { useSelector } from "react-redux";
import { displayLengthSelectorFactory, unitLabelSelectorFactory } from "./store/selectors";

export const useLengthField = ({label,
    length,
    callback,
    disabled = false,
    validateInput,
    defaultHelperText,
    isPrimary = true} : ILengthFieldProps) => {
    const displayLength = useSelector(displayLengthSelectorFactory(length, isPrimary));
    const [state, dispatch] = useReducer(TextFieldReducer, { ...initialTextFieldState, helperText: defaultHelperText ?? "", stringValue: toLengthString(displayLength) });
    const { stringValue, isValid, helperText } = state;
    const lengthLabel = useSelector(unitLabelSelectorFactory(label, isPrimary));
    const [localUnit, setLocalUnit] = useState(length.unit);
    const { t } = useTranslation();

    const inputCallBack = useCallback((value: number) => {
        const storeUnit = isPrimary ? Units.Millimeter : Units.Meter;
        if (callback && displayLength.value !== value) {
            const convertedLength = convertTo({ ...displayLength, value }, storeUnit); 
            callback(convertedLength);
        }
    }, [callback, displayLength, isPrimary]);
    
    useEffect(() => {
        const precision = displayLength.unit === Units.Millimeter ? 0 : 1;
        if (localUnit !== displayLength.unit) {
            setLocalUnit(displayLength.unit);
            dispatch(setStringValue(toLengthString(displayLength, precision, false)));
        } else {
            dispatch(updateStringValue(toLengthString(displayLength, precision, false)));
        }

    }, [dispatch, displayLength, localUnit]);

    useEffect(() =>{

        dispatch(setHelperText(defaultHelperText ?? ""));
        

    }, [dispatch, defaultHelperText]);

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const stringValue = e.currentTarget.value.trim().replace("-","");
        const numberValue = Number(stringValue);
        let helperText = defaultHelperText ?? "";
        let isValid = true;
        if (!validateNumberInput(stringValue, displayLength.unit)) {
            return;
        }
        if (validateInput) {
            isValid = validateInput(numberValue);
            if (!isValid) {
                helperText = t(LocalizationKeys.InvalidEntry);
            }
        }
        if (stringValue.length === 0) {
            isValid = false;
            helperText = t(LocalizationKeys.MissingValue);
        }
        dispatch(setStringValue(stringValue));
        dispatch(setHelperText(helperText));
        dispatch(setIsValid(isValid));
        inputCallBack(numberValue);
    }, [inputCallBack, t, validateInput, displayLength.unit, defaultHelperText]);

    return { label: lengthLabel, value: stringValue, isValid, onChange, helperText, dispatch, disabled };
}

export const validatePositiveInt = (value: number, includeZero = false) => {
    const start = includeZero ? 0 : 1;
    return !Number.isNaN(value) && value >= start;
}

export const parseInput = (value: string, defaultValue = 0): number => {
    let input = value as string;
    let parsedInput: string[] = [];
    let decimal = false;
    for (let i = 0; i < input.length; i++) {
        const char = input[i];
        if ((char >= '0' && char <= '9')) {
            parsedInput.push(char)
        }

        if (char === '.' && !decimal) {
            decimal = true
        }
    }
    const stringInput = parsedInput.join("");
    const number = Number.parseInt(stringInput) || defaultValue;
    return number;
}

function regexCompare(rawString: string, regex: RegExp): boolean {
    return rawString.length === 0 || rawString.match(regex) ? true : false;
} 

export function validateNumberInput(rawString: string, unit: Unit): boolean {
    const decimalRegExp = /^(\d+)+([.]?\d{0,2})?$/;
    const intRegExp = /^(\d+)$/;
    if (unit === Units.Millimeter) {
        return regexCompare(rawString, intRegExp);
    }
    return regexCompare(rawString, decimalRegExp);
}