import { Container, Text } from '@inlet/react-pixi';
import React from 'react';
import { LengthMarker } from '../../../markers/length/LengthMarker';
import { LineMarker } from '../../../markers/line/LineMarker';
import { LabelToleranceMarker } from '../../../markers/tolerance/LabelToleranceMarker';
import { BreakoutAnnotation } from '../annotation/BreakoutAnnotation';
import { BreakoutProps } from '../types';
import { useBreakoutMarkers } from './hooks';
import { BreakoutMarkersContainer, BreakoutStaggerText } from './types';

export const BreakoutMarkers: React.FC<BreakoutProps> = (props) => {
    const { x, y, trunkLengthMarker, staggerMarkers, hasManyConnectors, staggerTextProps, toleranceMarker, breakoutAnnotationProps, showABMarkers, isPigtailWithNoLeg } = useBreakoutMarkers(props);
    return (
        <>
            {
                x !== 0 ? <Container name={BreakoutMarkersContainer} x={x} y={y} interactive>
                    {
                        (showABMarkers || hasManyConnectors) && !isPigtailWithNoLeg &&
                            <>
                                <LengthMarker {...trunkLengthMarker} />
                                {staggerMarkers.map(s => (
                                    <LineMarker key={`${s.coordinate.x}` + s.text} {...s} />
                                ))}
                                <Text name={BreakoutStaggerText} {...staggerTextProps} />
                            </>
                    }
                    {toleranceMarker && toleranceMarker.isPigtail && <LabelToleranceMarker {...toleranceMarker} />}
                    <BreakoutAnnotation {...breakoutAnnotationProps} />
                </Container> : null
            }
        </>
    )
}