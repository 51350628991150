import React from "react";
import { Container, Text } from "@inlet/react-pixi";
import {
  CABContainer,
  CableProps,
  EndDesignationText,
  initialCableProps,
} from "./types";
import { Side } from "./side/Side";
import { useCable } from "./hooks";
import { LineMarker } from "../markers/line/LineMarker";
import { ColorContext } from "../color/types";
import { AnnotationContext } from "../../workspace/annotation/store/types";
import { MarkerSettingsContext } from "../../workspace/store/types";
import { HighlightContext } from "./breakout/connector-furcation/highlight/types";
import { PullingGrip } from "./pulling-grip/PullingGrip";

export const Cable: React.FC<CableProps> = (props = initialCableProps) => {
  const {
    markerSettings,
    annotationContext,
    colorContext,
    highlightContext,
    tipToTipMarkerProps,
    furcationToFurcationMarkerProps,
    endADesignationLabelProps,
    endAPullingGrip,
    endBDesignationLabelProps,
    endBPullingGrip,
    sideAProps,
    sideBProps
  } = useCable(props);

  return (
    <Container name={CABContainer}>
      <MarkerSettingsContext.Provider value={markerSettings}>
        <AnnotationContext.Provider value={annotationContext}>
          <ColorContext.Provider value={colorContext}>
            <HighlightContext.Provider value={highlightContext}>
              <Text name={EndDesignationText} {...endADesignationLabelProps} />
              <PullingGrip {...endAPullingGrip} />
              <Text name={EndDesignationText} {...endBDesignationLabelProps} />
              <PullingGrip {...endBPullingGrip} />
              {
                markerSettings.overallLengthTypeMarker === "furcation" ?
                <LineMarker {...furcationToFurcationMarkerProps} /> 
                :
                <LineMarker {...tipToTipMarkerProps} />
              }
              <Side {...sideAProps} />
              <Side {...sideBProps} />
            </HighlightContext.Provider>
          </ColorContext.Provider>
        </AnnotationContext.Provider>
      </MarkerSettingsContext.Provider>
    </Container>
  );
};
