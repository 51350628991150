import React from 'react';
import { FilledTextField } from '@orbit/text-field';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { useGeneralSetup } from './hooks';
import { TitledField } from './titled-field/TitledField';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../../localization/types';
import { Dialog } from '@orbit/dialog';
import { ColorDialog } from '../../../../ui/dialog/color/ColorDialog';
import { Typography } from '@orbit/theme-provider';
import { FiberCount } from "./fibercount-field/FiberCount";
import { FormControlLabel, RadioGroup, Radio } from '@mui/material';
import { Palette } from '@mui/icons-material';
import { IconButton } from '@orbit/icon-button';
import { HelpIcon } from '../../../../ui/icon/help-icon/HelpIcon';
import { OverallLengthTypeSelect } from '../../../../ui/select/overall-length-type/OverallLengthTypeSelect';

export const GeneralSetup: React.FC = () => {
    const {
        aLength,
        bLength,
        fiberCountProps,
        cableEnvironment,
        cableType,
        fiberType,
        colorDialogProps,
        displayStep,
        endA,
        endB,
        flameRating,
        jacket,
        overallLengthType,
        overallLength,
        shapeField,
        pullingGrip,
        outerDiameter
    } = useGeneralSetup();
    const { t } = useTranslation();
    return (
        <div className="general-setup setup" hidden={!displayStep}>
            <Dialog {...shapeField.dialogProps} />
            <TitledField title={t(LocalizationKeys.Shape)}>
                <RadioGroup row value={shapeField.radioProps.value}>
                    {shapeField.radioProps.options.map((o, i) =>
                        <span key={`shape-type-${i}`}>
                            <FormControlLabel className={o.className} label={o.label} control={<Radio {...o.radioButtonProps} />} />
                            <span className='label-help-shift'>
                                <HelpIcon text={o.help} />
                            </span>
                        </span>
                    )}
                </RadioGroup>
            </TitledField>
            <FiberCount {...fiberCountProps} />
            <div className="field-container">
                <FormControl className='select'>
                    <InputLabel>{flameRating.label}</InputLabel>
                    <Select {...flameRating.select}>
                        { flameRating.options.map((o, i) => <MenuItem key={`flame-rating-${i}`} value={o}>{o}</MenuItem>)}
                    </Select>
                </FormControl>
            </div>
            <div className="field-container">
                <FormControl className='select'>
                    <InputLabel>{pullingGrip.label}</InputLabel>
                    <Select {...pullingGrip.select}>
                        { pullingGrip.options.map((o, i) => <MenuItem key={`pulling-grip-${i}`} value={o}>{o}</MenuItem>)}
                    </Select>
                    <FormHelperText>{t(LocalizationKeys.PullingGripHelp)}</FormHelperText>
                </FormControl>
            </div>
            <div className="field-container with-hint">
                <FormControl className='select'>
                    <InputLabel>{cableType.label}</InputLabel>
                    <Select {...cableType.select}>
                        { cableType.options.map((o, i) => <MenuItem key={`cable-type-${i}`} value={o}>{o}</MenuItem>)}
                    </Select>
                </FormControl>
                <Typography variant="caption" label={cableType.hint} />
            </div>
            <div className="field-container">
                <FormControl className='select'>
                    <InputLabel>{fiberType.label}</InputLabel>
                    <Select {...fiberType.select}>
                        { fiberType.options.map((o, i) => <MenuItem key={`fiber-type-${i}`} value={o}>{o}</MenuItem>)}
                    </Select>
                </FormControl>
            </div>
            <div className="field-container">
                <FormControl className='select'>
                    <InputLabel>{outerDiameter.label}</InputLabel>
                    <Select {...outerDiameter.select}>
                        { outerDiameter.options.map((o, i) => <MenuItem key={`outer-diameter-${i}`} value={o}>{o}</MenuItem>)}
                    </Select>
                </FormControl>
            </div>
            <TitledField title={t(LocalizationKeys.Environment)}>
                <RadioGroup row value={cableEnvironment.value}>
                    { cableEnvironment.options.map((o, i) => <FormControlLabel key={`environment-type-${i}`} className={o.className} label={o.label} control={<Radio {...o.radioButtonProps} />} />) }
                </RadioGroup>
            </TitledField>
            <div className="field-container">
                <div className="field">
                        <FilledTextField {...jacket.field} InputProps={{ endAdornment: <IconButton {...jacket.icon} onClick={jacket.onClick} icon={<Palette />} />, readOnly: true }} />
                </div>
            </div>
            <div className="field-container horizontal">
                <div className="field">
                    <OverallLengthTypeSelect overallLengthType={overallLengthType}/>
                </div>
                <div className="field">
                    <FormControl>
                        <FilledTextField {...overallLength} />
                        <FormHelperText>{overallLength.error ? '' : t(LocalizationKeys.OverallLengthHelp)}</FormHelperText>
                    </FormControl>
                </div>
            </div>
            <div className="field-container horizontal character-counter">
                <div className="field">
                    <FilledTextField {...endA} />
                </div>
                <div className="field">
                    <FilledTextField {...endB} />
                </div>
            </div>
            <div className="field-container horizontal">
                <div className="field">
                    <FormControl>
                        <FilledTextField {...aLength} />
                        <FormHelperText>{aLength.error ? '' : t(LocalizationKeys.LengthA0Help)}</FormHelperText>
                    </FormControl>
                </div>
                <div className="field">
                    <FormControl>
                        <FilledTextField {...bLength} />
                        <FormHelperText>{bLength.error ? '' : t(LocalizationKeys.LengthB0Help)}</FormHelperText>
                    </FormControl>
                </div>
            </div>
            <ColorDialog {...colorDialogProps} />
        </div>
    )
}