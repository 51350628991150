import React from "react"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"

export const OverallLengthTypeSelect = (props) => {
    const { overallLengthType } = props
    return (
        <FormControl className='select'>
           <InputLabel>{overallLengthType.label}</InputLabel>
            <Select {...overallLengthType.select}>
                {overallLengthType.options.map((o, i) => 
                    <MenuItem key={`overall-length-type-${i}`} value={o}>
                        {overallLengthType.select.getLabel(o)}
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    )
}