import { defaultTolerances, ITolerance } from "../../../pixi/markers/tolerance/types";
import { IAssembly, initialAssembly } from "../../assembly/types";
import { IConnectorRowProps } from "../../offscreen/connector-table/types";

export interface IDocumentInfo {
    showToleranceNotes: boolean;
    inch: ITolerance;
    millimeter: ITolerance;
    fileName?: string;
}

export interface IDocumentReportTitle {
    headerTitle: string;
    drawingTitle: string;
    showCableOverview: boolean;
};

export interface IDocumentHeader { 
    drawingNumber: string;
    description: string;
    application: string;
    endADescription: string;
    endBDescription: string;
};

export interface IDocumentFooter { 
    approvalDate: string;
    inServiceDate: string;
    revision: string;
    location: string;
    drawnBy: string;
    notes: string;
};

export interface IDocumentPages {
    buildPlan: boolean;
    polarityDiagram: boolean;
    connectorViews: boolean;
};

export interface IUserProvidedImage {
    fileName: string;
    objectUrl: string;
}

export interface IDocumentDrawing {
    assembly: IAssembly;
    connectorRows: IConnectorRowProps[];
    userProvidedImages: IUserProvidedImage[];
}

export type AssemblyDocument = IDocumentInfo & IDocumentDrawing;

export const initialAssemblyDocument: AssemblyDocument = {
    assembly: initialAssembly,
    connectorRows: [],
    showToleranceNotes: true,
    inch: defaultTolerances.inch,
    millimeter: defaultTolerances.millimeter,
    userProvidedImages: []
}