import { createContext } from "react";
import { defaultLabelTolerances, defaultTolerances, ICustomTolerances, ITolerance } from "../../pixi/markers/tolerance/types";
import { OverallLengthType } from "../assembly/types";

export const Status = {
    InitialLoad: "initialLoad",
    Loading: "loading",
    Saving: "saving",
    Saved: "saved",
    Synchronizing: "synchronizing",
    Printing: "printing",
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type WorkspaceStatus = typeof Status [keyof typeof Status];

export const Organizations = {
    Corning: "Corning",
    Optimus: "Optimus",
    CTCM: "CTCM",
    TELCO: "TELCO"
} as const;

export type Organization = typeof Organizations [keyof typeof Organizations];

export const Regions = {
    NAFTA: "NAFTA", 
    EMEA: "EMEA",
} as const;

export type Region = typeof Regions [keyof typeof Regions];

export interface IMarkerSettings {
    showCableBaseReferences: boolean;
    showToleranceMarker: boolean;
    showTolerances: boolean;
    tolerance: ITolerance;
    customTolerances: ICustomTolerances;
    showPullingGrip: boolean;
    showTransitions?: boolean;
    showABMarkers?: boolean;
    showLabels?: boolean;
    overallLengthTypeMarker: OverallLengthType
}

export interface IMarkerState {
    showTolerances: boolean;
    tolerance: ITolerance;
    customTolerances: ICustomTolerances;
    showTransitions?: boolean;
    showABMarkers?: boolean;
}

export interface IWorkspaceSettings {
    showSettings: boolean;
    markers: IMarkerState;
}

export interface IWorkspaceDialogSettings {
    showAssemblyWizard: boolean;
    showAssemblyEdit: boolean;
    showBreakoutEdit: boolean;
    showBreakoutDetails: boolean;
    showTermsConditions: boolean;
    showIdle: boolean;
    showTimeOut: boolean;
    showAnnotationEdit: boolean;
    showHelp: boolean;
}

export interface IWorkspaceState {
    loggedIn: boolean;
    userId: number;
    company: string;
    userUom: string;
    status: WorkspaceStatus;
    settings: IWorkspaceSettings;
    dialogs: IWorkspaceDialogSettings;
}

export const initialMarkerSettings: IMarkerSettings = {
    showCableBaseReferences: true,
    showToleranceMarker: true,
    showTolerances: true,
    tolerance: defaultTolerances.millimeter,
    customTolerances: {
        legTolerance: defaultTolerances.millimeter,
        legPrimeTolerance: defaultTolerances.millimeter,
        legLabelTolerance: defaultLabelTolerances.millimeter,
    },
    showPullingGrip: true,
    showLabels: true,
    overallLengthTypeMarker: "tiptotip"
}

export const initialMarkerState: IMarkerState = {
    showTolerances: true,
    tolerance: defaultTolerances.millimeter,
    customTolerances: {
        legTolerance: defaultTolerances.millimeter,
        legPrimeTolerance: defaultTolerances.millimeter,
        legLabelTolerance: defaultLabelTolerances.millimeter,
    }
}

export const initialWorkspaceState: IWorkspaceState = {
    loggedIn: false,
    userId: 0,
    company: "",
    userUom: "",
    status: Status.Active,
    settings: {
        showSettings: false,
        markers: initialMarkerState
    },
    dialogs: {
        showAssemblyWizard: false,
        showAssemblyEdit: false,
        showBreakoutEdit: false,
        showBreakoutDetails: false,
        showTermsConditions: false,
        showIdle: false,
        showTimeOut: false,
        showAnnotationEdit: false,
        showHelp: false,
    }
}

export const MarkerSettingsContext = createContext(initialMarkerSettings);