import { useEffect, useCallback, useMemo, useState } from "react";
import { useSelector, batch } from "react-redux"
import { showAssemblyEditSelector } from "../../store/selectors"
import { assembliesLoadedSelector, currentAssemblyBootColorCombinationSelector, currentAssemblyConnectorsSelector, currentAssemblyHasMultiBootConnectorsSelector, currentAssemblyHasLCDuplexConnectorsSelector, currentAssemblyHasSCDuplexConnectorsSelector, currentAssemblySelector, currentCustomTolerancesSelector, currentJacketColorSelector, currentPullingGripSelector, filteredJacketColorSelector, tolerancesSelector, currentFurcationOuterDiameterSelector, sideBreakoutsSelectorFactory, currentOverallLengthTypeSelector, assemblyHasMultipleConnectorsSelector } from "../../assembly/store/selectors";
import { updateOverallLength, updateAssemblyInfo, updateAssemblyPalette, setConnectors, updateConnectors, setupPullingGrip, setupJacketColor, setupOverallLength, setupEndDesignation, setupLegLengthTolerances, setupLegPrimeTolerances, setupLegLabelTolerances, setupOuterDiameter, setupSideLegColor, setupSideTrunkColor, setupOverallLengthType } from "../../assembly/store/reducer";
import { convertTo, formattedConvertTo, Length, Unit, Units } from "../../assembly/length/types";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../localization/types";
import { useLengthField } from "../../assembly/length/hooks";
import { IColor, IColorDialogProps } from "../../../ui/dialog/color/types";
import { IAssemblyPalette } from "../../assembly/palette/types";
import { BootColorCombination, BootColorCombinations, FurcationOuterDiameters, getBootColorCombination, OverallLengthType, overallLengthTypeOptions, OverallLengthTypes, PullingGrips } from "../../assembly/types";
import { useSelectInput } from "../../../ui/select/hooks";
import { setStatus, showAssemblyEdit } from "../../store/reducer";
import { Status } from "../../store/types";
import { useStoreDispatch } from "../../../store/hooks";
import { DialogProps, IDialogActions } from "@orbit/dialog";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { TooltipPlacement } from "@orbit/icon-button";
import { unitSelector } from "../../assembly/length/store/selectors";
import { convertToleranceUnits, defaultLabelTolerances } from "../../../pixi/markers/tolerance/types";
import { PIGTAIL_NO_LEG, isDuplex } from "../../assembly/connector/types";
import { IConnectorWithPositions, Sides } from "../../assembly/breakout/types";
import { getOverallLengthTypeLabel } from "../../wizard/cable-setup/general-setup/hooks";

export const useAssemblyEditDialog = () => {
    const open = useSelector(showAssemblyEditSelector);
    const assembly = useSelector(currentAssemblySelector);
    const { defaultJacketColor, jacketColor, initialJacketColor, jacket, colorDialogProps, resetJacketColor, updateInitialJacketColor } = useJacket();
    const { currentPullingGrip, initialPullingGrip, pullingGrip, resetPullingGrip, updateInitialPullingGrip } = usePullingGrip();
    const { currentOverallLengthType, initialOverallLengthType, overallLengthType, resetOverallLengthType, updateOverallLengthType } = useOverallLengthType();
    const { currentOuterDiameter, initialOuterDiameter, outerDiameter, resetOuterDiameter, updateInitialOuterDiameter } = useOuterDiameter();
    const { 
        resetTolerances,
        areTolerancesInvalid,
        areTolerancesModified,
        updateInitialTolerances,
        toleranceStates,
        minLegLengthFieldProps, 
        maxLegLengthFieldProps,
        minLegPrimeLengthFieldProps,
        maxLegPrimeLengthFieldProps,
        minLegLabelLengthFieldProps,
        maxLegLabelLengthFieldProps, 
    } = useTolerances();
    const { hasDuplexConnectors, resetBootColors, updateInitialBootColor, persistBootColors, bootColorModified, bootColor } = useBootColors();
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();
    const [initialOverallLength, setInitialOverallLength] = useState(assembly.overallLength ?? { id: 0, value: 0, unit: Units.Meter });
    const [overallLength, setOverallLength] = useState(assembly.overallLength ?? { id: 0, value: 0, unit: Units.Meter });
    const onOverallLengthChange = useCallback((l: Length) => {
        setOverallLength(l);
        storeDispatch(setupOverallLength(l));
    }, [storeDispatch]);

    const overallLengthField = useLengthField({ label: t(LocalizationKeys.OverallLength), length: overallLength, callback: onOverallLengthChange, disabled: false, isPrimary: false });
    const resetOverallLength = useCallback(() => {
        setOverallLength(initialOverallLength);
        storeDispatch(setupOverallLength(initialOverallLength));
    },[storeDispatch, initialOverallLength]);

    const [initialEndADesignation, setInitialEndADesignation] = useState(assembly.assemblyInfo?.endADesignation ?? "");
    const [endADesignation, setEndADesignation] = useState(assembly.assemblyInfo?.endADesignation ?? "");
    
    const onEndADesignationChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const designation = e.currentTarget.value;
        setEndADesignation(designation);
        storeDispatch(setupEndDesignation({ end: Sides.SideA, designation }));
    }, [storeDispatch]);

    const resetEndADesignation = useCallback(() => {
        setEndADesignation(initialEndADesignation);
        storeDispatch(setupEndDesignation({ end: Sides.SideA, designation: initialEndADesignation }));
    }, [storeDispatch, initialEndADesignation]);

    const endA = {
        className: "end-a-designation-field",
        palette: MainPalettes.primary,
        label: t(LocalizationKeys.EndDesignation, { side: "A" }),
        value: endADesignation,
        maxLength: 20,
        onChange: onEndADesignationChange
    };
    
    const [initialEndBDesignation, setInitialEndBDesignation] = useState(assembly.assemblyInfo?.endBDesignation ?? "");
    const [endBDesignation, setEndBDesignation] = useState(assembly.assemblyInfo?.endBDesignation ?? "");
    const onEndBDesignationChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const designation = e.currentTarget.value;
        setEndBDesignation(designation);
        storeDispatch(setupEndDesignation({ end: Sides.SideB, designation }));
    }, [storeDispatch]);

    const resetEndBDesignation = useCallback(() => {
        setEndBDesignation(initialEndBDesignation);
        storeDispatch(setupEndDesignation({ end: Sides.SideB, designation: initialEndBDesignation }));
    }, [storeDispatch, initialEndBDesignation]);

    const endB = {
        className: "end-b-designation-field",
        palette: MainPalettes.primary,
        label: t(LocalizationKeys.EndDesignation, { side: "B" }),
        value: endBDesignation,
        maxLength: 20,
        onChange: onEndBDesignationChange
    };

    useEffect(() => {
        if (!open) {
            if (assembly.overallLength) {
                setOverallLength(assembly.overallLength);
            }
            if (assembly.assemblyInfo) {
                setEndADesignation(assembly.assemblyInfo.endADesignation ?? "");
                setEndBDesignation(assembly.assemblyInfo.endBDesignation ?? "");
            }
        }
    }, [storeDispatch, open, assembly.overallLength, assembly.assemblyInfo, overallLength, initialOverallLength, initialEndADesignation, endADesignation, initialEndBDesignation, endBDesignation, resetBootColors, resetPullingGrip, resetOverallLength]);

    useEffect(() => {
        if (overallLength === initialOverallLength) {
            setInitialOverallLength(assembly.overallLength ?? { id: 0, value: 0, unit: Units.Meter });
        }
    }, [overallLength, assembly.overallLength, initialOverallLength]);

    useEffect(() => {
        if (endADesignation === initialEndADesignation) {
            setInitialEndADesignation(assembly.assemblyInfo?.endADesignation ?? "");
        }
    }, [endADesignation, initialEndADesignation, assembly.assemblyInfo?.endADesignation]);

    useEffect(() => {
        if (endBDesignation === initialEndBDesignation) {
            setInitialEndBDesignation(assembly.assemblyInfo?.endBDesignation ?? "");
        }
    }, [endBDesignation, initialEndBDesignation, assembly.assemblyInfo?.endBDesignation])

    const updateInitialOverallLength = useCallback(() => {
        setInitialOverallLength(overallLength);
    }, [overallLength]);

    const updateInitialEndADesignation = useCallback(() => {
        setInitialEndADesignation(endADesignation);
    }, [endADesignation]);

    const updateInitialEndBDesignation = useCallback(() => {
        setInitialEndBDesignation(endBDesignation)
    }, [endBDesignation]);

    const updateInitialProperties = useCallback(() => {
        updateInitialPullingGrip();
        updateOverallLengthType();
        updateInitialJacketColor();
        updateInitialOverallLength();
        updateInitialEndADesignation();
        updateInitialEndBDesignation();
        updateInitialOuterDiameter();
        updateInitialTolerances();
        updateInitialBootColor();
    }, [updateInitialPullingGrip, updateOverallLengthType, updateInitialJacketColor, updateInitialOverallLength, updateInitialEndADesignation, updateInitialEndBDesignation, updateInitialOuterDiameter, updateInitialTolerances, updateInitialBootColor]);

    const resetAssemblyValues = useCallback(() => {
        resetPullingGrip();
        resetOverallLengthType();
        resetJacketColor();
        resetOverallLength();
        resetEndADesignation();
        resetEndBDesignation();
        resetOuterDiameter();
        resetTolerances();
        resetBootColors();
    }, [resetPullingGrip, resetOverallLengthType, resetJacketColor, resetOverallLength, resetEndADesignation, resetEndBDesignation, resetOuterDiameter, resetTolerances, resetBootColors]);

    const onClose = useCallback(() => {
        storeDispatch(showAssemblyEdit(false));
        colorDialogProps.onClose();
        resetAssemblyValues();
    }, [storeDispatch, colorDialogProps, resetAssemblyValues]);

    const onSave = useCallback(() => {
        storeDispatch(setStatus(Status.Synchronizing));
        const convertedOverallLength = convertTo(overallLength, Units.Meter);
        storeDispatch(updateOverallLength(convertedOverallLength));
        if (assembly.palette) {
            const palette: IAssemblyPalette = { ...assembly.palette, jacketColor: jacketColor.name };
            storeDispatch(updateAssemblyPalette(palette));
        }
        if (assembly.assemblyInfo) {
            storeDispatch(updateAssemblyInfo({
                ...assembly.assemblyInfo,
                ...toleranceStates,
                endADesignation,
                endBDesignation,
                pullingGrip: pullingGrip.select.value,
                furcationOuterDiameter: outerDiameter.select.value,
                overallLengthType: overallLengthType.select.value
            }));
        }
        persistBootColors();
        storeDispatch(showAssemblyEdit(false));
        updateInitialProperties();
        colorDialogProps.onClose();
    }, [storeDispatch, overallLength, assembly.assemblyInfo, endADesignation, endBDesignation, pullingGrip.select.value, overallLengthType.select.value, outerDiameter.select.value, assembly.palette, jacketColor, toleranceStates, persistBootColors, colorDialogProps, updateInitialProperties]);

    const overallLengthFieldProps = {
        className: "overall-length-field",
        palette: MainPalettes.primary,
        label: overallLengthField.label,
        value: overallLengthField.value,
        onChange: overallLengthField.onChange,
        error: !overallLengthField.isValid,
        helperText: overallLengthField.helperText 
    };

    const saveDisabled = useMemo(() => {
        const pullingGripModified = currentPullingGrip !== initialPullingGrip;
        const jacketColorModified = defaultJacketColor !== initialJacketColor;
        const overallLengthModified = initialOverallLength.value !== overallLength.value;
        const outerDiameterModified = currentOuterDiameter !== initialOuterDiameter;
        const overallLengthTypeModified = currentOverallLengthType !== initialOverallLengthType;

        let endADesignationModified = endADesignation.length > 0;
        if (assembly.assemblyInfo?.endADesignation) {
            endADesignationModified = initialEndADesignation !== endADesignation;
        }
        let endBDesignationModified = endBDesignation.length > 0;
        if (assembly.assemblyInfo?.endBDesignation) {
            endBDesignationModified = initialEndBDesignation !== endBDesignation;
        }

        return (!pullingGripModified && !jacketColorModified && !overallLengthModified && !outerDiameterModified && !endADesignationModified && !endBDesignationModified && !areTolerancesModified && !bootColorModified && !overallLengthTypeModified) || !overallLengthField.isValid || !areTolerancesInvalid;
    }, [currentPullingGrip, initialPullingGrip, currentOverallLengthType,  initialOverallLengthType, defaultJacketColor, initialJacketColor, initialEndADesignation, initialEndBDesignation, initialOverallLength.value, overallLength.value, assembly.assemblyInfo, endADesignation, endBDesignation, overallLengthField.isValid, areTolerancesInvalid, areTolerancesModified, bootColorModified, initialOuterDiameter, currentOuterDiameter]);

    const actions: IDialogActions = {
        cancelText: t(LocalizationKeys.Cancel),
        onCancelClick: onClose,
        confirmText: t(LocalizationKeys.Save),
        disableConfirm: saveDisabled,
        onConfirmClick: onSave,
        actionGuidance: {
            button: 'confirm',
            severity: 'standard'
        },
    };
 
    const dialogProps: DialogProps = {
        open,
        className: "assembly-edit-dialog toggle-pointer-events",
        title: t(LocalizationKeys.EditAssembly),
        modal: false,
        actions: actions,
        onClose: onClose
    };

    return { 
        t,
        dialogProps,
        pullingGrip,
        bootColor,
        jacket,
        overallLength: overallLengthFieldProps, 
        endA,
        endB,
        outerDiameter,
        colorDialogProps,
        minLegLengthFieldProps,
        maxLegLengthFieldProps,
        minLegPrimeLengthFieldProps,
        maxLegPrimeLengthFieldProps,
        minLegLabelLengthFieldProps,
        maxLegLabelLengthFieldProps, 
        hasDuplexConnectors,
        overallLengthType
    };
}

const useJacket = () => {
    const jacketColors = useSelector(filteredJacketColorSelector);
    const defaultJacketColor = useSelector(currentJacketColorSelector);
    const sideABreakout = useSelector(sideBreakoutsSelectorFactory(Sides.SideA));
    const sideBBreakout = useSelector(sideBreakoutsSelectorFactory(Sides.SideB));
    const isSideAPigtailNoLeg = sideABreakout[0].furcation.groups[0].connectors[0].type === PIGTAIL_NO_LEG;
    const isSideBPigtailNoLeg = sideBBreakout[0].furcation.groups[0].connectors[0].type === PIGTAIL_NO_LEG;
    const [initialJacketColor, setInitialJacketColor] = useState(defaultJacketColor);
    const [jacketColor, setJacketColor] = useState(defaultJacketColor);
    const [showColorDialog, setShowColorDialog] = useState(false);
    const storeDispatch = useStoreDispatch();
    const { t } = useTranslation();

    const openColorDialog = useCallback(() => {
        setShowColorDialog(true);
    }, [])

    const closeColorDialog = useCallback(() => {
        setShowColorDialog(false);
    }, [])

    useEffect(() => {
        setJacketColor(defaultJacketColor);
        if (jacketColor === initialJacketColor) {
            setInitialJacketColor(defaultJacketColor);
        }
    }, [defaultJacketColor, initialJacketColor, jacketColor]);

    const jacketFieldProps = {
        className: "color-picker",
        label: `A0/B0 ${t(LocalizationKeys.JacketColor)}`,
        value: jacketColor.name,
        palette: MainPalettes.primary,
    };

    const jacketIcon = {
        className: "palette-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        placement: "bottom" as TooltipPlacement
    }

    const jacket = {
        field: jacketFieldProps,
        icon: jacketIcon,
        onClick: openColorDialog,
    }

    const onColorButtonClick = useCallback((color: IColor) => {
        setJacketColor(color);
        if (isSideAPigtailNoLeg) {
            storeDispatch(setupSideLegColor({side: Sides.SideA, color: color.name}))
            storeDispatch(setupSideTrunkColor({side: Sides.SideA, color: color.name}))
        }
        else if (isSideBPigtailNoLeg) {
            storeDispatch(setupSideLegColor({side: Sides.SideB, color: color.name}))
            storeDispatch(setupSideTrunkColor({side: Sides.SideB, color: color.name}))
        }
        storeDispatch(setupJacketColor(color.name));
    }, [isSideAPigtailNoLeg, isSideBPigtailNoLeg, storeDispatch]);

    const resetJacketColor = useCallback(() => {
        setJacketColor(initialJacketColor);
        storeDispatch(setupJacketColor(initialJacketColor.name));
    }, [storeDispatch, initialJacketColor]);

    const updateInitialJacketColor = useCallback(() => {
        setInitialJacketColor(jacketColor);
    }, [jacketColor]);

    const colorDialogProps: IColorDialogProps = {
        open: showColorDialog,
        onClose: closeColorDialog,
        className: "assembly-jacket",
        currentColor: jacketColor,
        colors: jacketColors,
        onColorButtonClick
    }

    return { defaultJacketColor, jacketColor, initialJacketColor, jacket, colorDialogProps, resetJacketColor, updateInitialJacketColor };
}
export const useOverallLengthType = () => {
    const currentOverallLengthType = useSelector(currentOverallLengthTypeSelector);
    const [initialOverallLengthType, setInitialOverallLengthType] = useState(currentOverallLengthType);
    const [overallLengthType, setOverallLengthType] = useState(currentOverallLengthType);
    const { t } = useTranslation();
    const overallLengthTypeLabel = t(LocalizationKeys.OverallLengthType);
    const { label, value } = useSelectInput(overallLengthTypeOptions, overallLengthTypeLabel, "", overallLengthType);
    const storeDispatch = useStoreDispatch();
    const disabled = !useSelector(assemblyHasMultipleConnectorsSelector);
    useEffect(() => {
        setOverallLengthType(currentOverallLengthType);
        if (overallLengthType === initialOverallLengthType) {
            setInitialOverallLengthType(currentOverallLengthType);
        }
    }, [currentOverallLengthType, initialOverallLengthType, overallLengthType]);

    const resetOverallLengthType = useCallback(() =>{
        setOverallLengthType(initialOverallLengthType);
        storeDispatch(setupOverallLengthType(initialOverallLengthType ?? OverallLengthTypes.TipToTip));
    }, [storeDispatch, initialOverallLengthType]);

    const onOverallLengthTypeChange = useCallback((e) => {
        const overallLengthType = e.target.value;
        setOverallLengthType(overallLengthType);
        storeDispatch(setupOverallLengthType(overallLengthType));
    }, [storeDispatch]);

    const updateOverallLengthType = useCallback(() => {
        setInitialOverallLengthType(overallLengthType);
    }, [overallLengthType]);

    return {
        currentOverallLengthType,
        initialOverallLengthType,
        resetOverallLengthType,
        updateOverallLengthType,
        overallLengthType: {
            label,
            select: {
                variant: "filled" as "filled",
                value: disabled ? "tiptotip" : value as OverallLengthType,
                onChange: onOverallLengthTypeChange,
                disabled,
                getLabel: getOverallLengthTypeLabel
            },
            options: overallLengthTypeOptions
        }
    };
}
export const usePullingGrip = () => {
    const currentPullingGrip = useSelector(currentPullingGripSelector);
    const [initialPullingGrip, setInitialPullingGrip] = useState(currentPullingGrip);
    const [pullingGrip, setPullingGrip] = useState(currentPullingGrip);
    const { t } = useTranslation();
    const pullingGrips = Object.values(PullingGrips);
    const pullingGripLabel = t(LocalizationKeys.PullingGrip);
    const { label, value, options } = useSelectInput(pullingGrips, pullingGripLabel, "", pullingGrip);
    const storeDispatch = useStoreDispatch();

    useEffect(() => {
        setPullingGrip(currentPullingGrip);
        if (pullingGrip === initialPullingGrip) {
            setInitialPullingGrip(currentPullingGrip);
        }
    }, [currentPullingGrip, initialPullingGrip, pullingGrip]);

    const resetPullingGrip = useCallback(() =>{
        setPullingGrip(initialPullingGrip);
        storeDispatch(setupPullingGrip(initialPullingGrip));
    }, [storeDispatch, initialPullingGrip]);

    const onPullingGripChange = useCallback((e) => {
        const pullingGrip = e.target.value;
        setPullingGrip(pullingGrip);
        storeDispatch(setupPullingGrip(pullingGrip));
    }, [storeDispatch]);

    const updateInitialPullingGrip = useCallback(() => {
        setInitialPullingGrip(pullingGrip);
    }, [pullingGrip]);

    return {
        currentPullingGrip,
        initialPullingGrip,
        resetPullingGrip,
        updateInitialPullingGrip,
        pullingGrip: {
            label,
            select: {
                variant: "filled" as "filled",
                value,
                onChange: onPullingGripChange
            },
            options
        }
    };
}

export const useOuterDiameter = () => {
    const currentOuterDiameter = useSelector(currentFurcationOuterDiameterSelector);
    const [initialOuterDiameter, setInitialOuterDiameter] = useState(currentOuterDiameter);
    const [outerDiameter, setOuterDiameter] = useState(currentOuterDiameter);
    const { t } = useTranslation();
    const outerDiameters = FurcationOuterDiameters;
    const outerDiameterLabel = t(LocalizationKeys.FurcationOuterDiamater);
    const { label, value, options } = useSelectInput(outerDiameters, outerDiameterLabel, "", outerDiameter);
    const storeDispatch = useStoreDispatch();

    useEffect(() => {
        setOuterDiameter(currentOuterDiameter);
        if (outerDiameter === initialOuterDiameter) {
            setInitialOuterDiameter(currentOuterDiameter);
        }
    }, [currentOuterDiameter, initialOuterDiameter, outerDiameter]);

    const resetOuterDiameter = useCallback(() =>{
        setOuterDiameter(initialOuterDiameter);
        storeDispatch(setupOuterDiameter(initialOuterDiameter));
    }, [storeDispatch, initialOuterDiameter]);

    const onOuterDiameterChange = useCallback((e) => {
        const outerDiameter = e.target.value;
        setOuterDiameter(outerDiameter);
        storeDispatch(setupOuterDiameter(outerDiameter));
    }, [storeDispatch]);

    const updateInitialOuterDiameter = useCallback(() => {
        setInitialOuterDiameter(outerDiameter);
    }, [outerDiameter]);

    return {
        currentOuterDiameter,
        initialOuterDiameter,
        resetOuterDiameter,
        updateInitialOuterDiameter,
        outerDiameter: {
            label,
            select: {
                variant: "filled" as "filled",
                value,
                onChange: onOuterDiameterChange
            },
            options
        }
    };
}

export const useTolerances = () => {
    const customTolerances = useSelector(currentCustomTolerancesSelector);
    const defaultTolerances = useSelector(tolerancesSelector);
    const assembliesLoaded = useSelector(assembliesLoadedSelector);
    const { unit } = useSelector(unitSelector);
    const { t } = useTranslation();
    const noSignValues = ["","0","0.","0.0"];
    const storeDispatch = useStoreDispatch();

    const [initialMinLegLength, setInitialMinLegLength] = useState({ value: customTolerances.legTolerance.min, unit });
    const [initialMaxLegLength, setInitialMaxLegLength] = useState({ value: customTolerances.legTolerance.max, unit });

    const [initialMinLegPrimeLength, setInitialMinLegPrimeLength] = useState({ value: customTolerances.legPrimeTolerance.min, unit });
    const [initialMaxLegPrimeLength, setInitialMaxLegPrimeLength] = useState({ value: customTolerances.legPrimeTolerance.max, unit });

    const [initialMinLegLabelLength, setInitialMinLegLabelLength] = useState({ value: customTolerances.legLabelTolerance.min, unit });
    const [initialMaxLegLabelLength, setInitialMaxLegLabelLength] = useState({ value: customTolerances.legLabelTolerance.max, unit });

    const [minLegLength, setMinLegLength] = useState({ value: customTolerances.legTolerance.min, unit });
    const [maxLegLength, setMaxLegLength] = useState({ value: customTolerances.legTolerance.max, unit });

    const [minLegPrimeLength, setMinLegPrimeLength] = useState({ value: customTolerances.legPrimeTolerance.min, unit });
    const [maxLegPrimeLength, setMaxLegPrimeLength] = useState({ value: customTolerances.legPrimeTolerance.max, unit });

    const [minLegLabelLength, setMinLegLabelLength] = useState({ value: customTolerances.legLabelTolerance.min, unit });
    const [maxLegLabelLength, setMaxLegLabelLength] = useState({ value: customTolerances.legLabelTolerance.max, unit });

    const [hasLoadedAssemblies, setHasLoadedAssemblies] = useState(false);
    
    const onMinLegLengthChange = useCallback((l: Length) => { 
        setMinLegLength(formattedConvertTo(l, unit));
        const min = formattedConvertTo(l, Units.Millimeter).value;
        storeDispatch(setupLegLengthTolerances({ ...customTolerances.legTolerance, min }));
    },[storeDispatch, unit, customTolerances.legTolerance]);

    const onMaxLegLengthChange = useCallback((l: Length) => {
        setMaxLegLength(formattedConvertTo(l, unit));
        const max = formattedConvertTo(l, Units.Millimeter).value;
        storeDispatch(setupLegLengthTolerances({...customTolerances.legTolerance, max }));
    },[storeDispatch, unit, customTolerances.legTolerance]);

    const onMinLegPrimeLengthChange = useCallback((l: Length) => {
        setMinLegPrimeLength(formattedConvertTo(l, unit));
        const min = formattedConvertTo(l, Units.Millimeter).value;
        storeDispatch(setupLegPrimeTolerances({ ...customTolerances.legPrimeTolerance, min }));
    },[storeDispatch, unit, customTolerances.legPrimeTolerance]);
    
    const onMaxLegPrimeLengthChange = useCallback((l: Length) => {
        setMaxLegPrimeLength(formattedConvertTo(l, unit));
        const max = formattedConvertTo(l, Units.Millimeter).value;
        storeDispatch(setupLegPrimeTolerances({ ...customTolerances.legPrimeTolerance, max }));
    },[storeDispatch, unit, customTolerances.legPrimeTolerance]);

    const onMinLegLabelLengthChange = useCallback((l: Length) => {
        setMinLegLabelLength(formattedConvertTo(l, unit));
        const min = formattedConvertTo(l, Units.Millimeter).value;
        storeDispatch(setupLegLabelTolerances({ ...customTolerances.legLabelTolerance, min }));
    },[storeDispatch, unit, customTolerances.legLabelTolerance]);
    
    const onMaxLegLabelLengthChange = useCallback((l: Length) => {
        setMaxLegLabelLength(formattedConvertTo(l, unit));
        const max = formattedConvertTo(l, Units.Millimeter).value;
        storeDispatch(setupLegLabelTolerances({ ...customTolerances.legLabelTolerance, max }));
    },[storeDispatch, unit, customTolerances.legLabelTolerance]);


    const getHelpText = useCallback((isInputInvalid: Boolean, validationHelpText: string, minToleranceValue: string, maxToleranceValue: string, isLegLabelField: Boolean = false) => {
        if (!isInputInvalid) {
            const minDefaultTolerances = [defaultTolerances.inch.min, defaultTolerances.millimeter.min];
            const maxDefaultTolerances = [defaultTolerances.inch.max, defaultTolerances.millimeter.max];

            const maxLegLabelDefaultTolerances = [defaultLabelTolerances.inch.max, defaultLabelTolerances.millimeter.max];

            if (!isLegLabelField && (minDefaultTolerances.findIndex(m => m === parseInt(minToleranceValue)) === -1 || maxDefaultTolerances.findIndex(m => m === parseInt(maxToleranceValue)) === -1)) {
                return t(LocalizationKeys.DefaultTolerances, { 
                        minTolerance: unit === Units.Millimeter ? defaultTolerances.millimeter.min : defaultTolerances.inch.min, 
                        maxTolerance: unit === Units.Millimeter ? defaultTolerances.millimeter.max : defaultTolerances.inch.max,
                    });
            } else if (isLegLabelField && (parseFloat(minToleranceValue) !== 0 || maxLegLabelDefaultTolerances.findIndex(m => m === parseFloat(maxToleranceValue)) === -1)) {
                return t(LocalizationKeys.DefaultTolerances, { 
                        minTolerance: 0, 
                        maxTolerance: unit === Units.Millimeter ? defaultLabelTolerances.millimeter.max : defaultLabelTolerances.inch.max,
                    });
            }
        }
        
        return validationHelpText;
    },[t, unit, defaultTolerances]);

    const getDefaultText = useCallback((isLabelTolerance: boolean, unit: Unit) =>{
        const tolerances = isLabelTolerance ? defaultLabelTolerances : defaultTolerances;
        
        let minTolerance = tolerances.inch.min;
        let maxTolerance = tolerances.inch.max;
        if (unit === Units.Millimeter) {
            minTolerance = tolerances.millimeter.min;
            maxTolerance = tolerances.millimeter.max;
        }
        
        return t(LocalizationKeys.DefaultTolerances, { 
            minTolerance, 
            maxTolerance,
        });

    }, [t, defaultTolerances]);

    const minLegLengthField = useLengthField({ 
        length: minLegLength, 
        callback: onMinLegLengthChange, 
        disabled: false, 
        isPrimary: true,
        defaultHelperText: getDefaultText(false, unit)
    });

    const maxLegLengthField = useLengthField({ 
        length: maxLegLength, 
        callback: onMaxLegLengthChange, 
        disabled: false, 
        isPrimary: true 
    });

    const minLegPrimeLengthField = useLengthField({ 
        length: minLegPrimeLength, 
        callback: onMinLegPrimeLengthChange, 
        disabled: false, 
        isPrimary: true,
        defaultHelperText: getDefaultText(false, unit)
    });

    const maxLegPrimeLengthField = useLengthField({ 
        length: maxLegPrimeLength, 
        callback: onMaxLegPrimeLengthChange, 
        disabled: false, 
        isPrimary: true 
    });

    const minLegLabelLengthField = useLengthField({ 
        length: minLegLabelLength, 
        callback: onMinLegLabelLengthChange, 
        disabled: false, 
        isPrimary: true,
        defaultHelperText: getDefaultText(true, unit)
    });

    const maxLegLabelLengthField = useLengthField({ 
        length: maxLegLabelLength, 
        callback: onMaxLegLabelLengthChange, 
        disabled: false, 
        isPrimary: true 
    });

    const minLegLengthFieldProps = {
        className: "min-leg-length-field field-helper-text-container",
        palette: MainPalettes.primary,
        label: t(LocalizationKeys.MinTolerance),
        value: `${noSignValues.includes(minLegLengthField.value) ? "" : "-"}${minLegLengthField.value}`,
        onChange: minLegLengthField.onChange,
        error: !minLegLengthField.isValid,
        helperText: minLegLengthField.helperText, 
        units: unit,
    };


    const maxLegLengthFieldProps = {
        className: "max-leg-length-field field-helper-text-container",
        palette: MainPalettes.primary,
        label: t(LocalizationKeys.MaxTolerance),
        value: maxLegLengthField.value,
        onChange: maxLegLengthField.onChange,
        error: !maxLegLengthField.isValid,
        helperText: maxLegLengthField.helperText,
        units: unit, 
    };

    const minLegPrimeLengthFieldProps = {
        className: "min-leg-prime-length-field field-helper-text-container",
        palette: MainPalettes.primary,
        label: t(LocalizationKeys.MinTolerance),
        value: `${noSignValues.includes(minLegPrimeLengthField.value) ? "" : "-"}${minLegPrimeLengthField.value}`,
        onChange: minLegPrimeLengthField.onChange,
        error: !minLegPrimeLengthField.isValid,
        helperText: getHelpText(!minLegPrimeLengthField.isValid, minLegPrimeLengthField.helperText, minLegPrimeLengthField.value, maxLegPrimeLengthField.value),
        units: unit,
    };

    const maxLegPrimeLengthFieldProps = {
        className: "max-leg-prime-length-field field-helper-text-container",
        palette: MainPalettes.primary,
        label: t(LocalizationKeys.MaxTolerance),
        value: maxLegPrimeLengthField.value,
        onChange: maxLegPrimeLengthField.onChange,
        error: !maxLegPrimeLengthField.isValid,
        helperText: maxLegPrimeLengthField.helperText,
        units: unit,
    };

    const minLegLabelLengthFieldProps = {
        className: "min-leg-label-length-field field-helper-text-container",
        palette: MainPalettes.primary,
        label: t(LocalizationKeys.MinTolerance),
        value: `${noSignValues.includes(minLegLabelLengthField.value) ? "" : "-"}${minLegLabelLengthField.value}`,
        onChange: minLegLabelLengthField.onChange,
        error: !minLegLabelLengthField.isValid,
        helperText: getHelpText(!minLegLabelLengthField.isValid, minLegLabelLengthField.helperText, minLegLabelLengthField.value, maxLegLabelLengthField.value, true),
        units: unit,
    };

    const maxLegLabelLengthFieldProps = {
        className: "max-leg-label-length-field field-helper-text-container",
        palette: MainPalettes.primary,
        label: t(LocalizationKeys.MaxTolerance),
        value: maxLegLabelLengthField.value,
        onChange: maxLegLabelLengthField.onChange,
        error: !maxLegLabelLengthField.isValid,
        helperText: maxLegLabelLengthField.helperText,
        units: unit,
    };

    const resetTolerances = useCallback(() => {
        const legLengthMin = formattedConvertTo(initialMinLegLength, Units.Millimeter).value;
        const legLengthMax = formattedConvertTo(initialMaxLegLength, Units.Millimeter).value;
        setMinLegLength(formattedConvertTo(initialMinLegLength, unit));
        setMaxLegLength(formattedConvertTo(initialMaxLegLength, unit));

        const legPrimeMinLength = formattedConvertTo(initialMinLegPrimeLength, Units.Millimeter).value;
        const legPrimeMaxLength = formattedConvertTo(initialMaxLegPrimeLength, Units.Millimeter).value;        
        setMinLegPrimeLength(formattedConvertTo(initialMinLegPrimeLength, unit));
        setMaxLegPrimeLength(formattedConvertTo(initialMaxLegPrimeLength, unit));

        const legLabelMinLength = formattedConvertTo(initialMinLegLabelLength, Units.Millimeter).value;
        const legLabelMaxLength = formattedConvertTo(initialMaxLegLabelLength, Units.Millimeter).value;        
        setMinLegLabelLength(formattedConvertTo(initialMinLegLabelLength, unit));
        setMaxLegLabelLength(formattedConvertTo(initialMaxLegLabelLength, unit));

        batch(() => {
            storeDispatch(setupLegLengthTolerances({ min: legLengthMin, max: legLengthMax }));
            storeDispatch(setupLegPrimeTolerances({ min: legPrimeMinLength, max: legPrimeMaxLength }));
            storeDispatch(setupLegLabelTolerances({ min: legLabelMinLength, max: legLabelMaxLength }));
        });
    },[storeDispatch, unit, initialMinLegLength, initialMaxLegLength, initialMinLegPrimeLength, initialMaxLegPrimeLength, initialMinLegLabelLength, initialMaxLegLabelLength]);

    useEffect(() => {
        const convertedLegTolerance = convertToleranceUnits({ ...customTolerances.legTolerance }, Units.Millimeter, unit);
        const convertedLegPrimeTolerance = convertToleranceUnits({ ...customTolerances.legPrimeTolerance }, Units.Millimeter, unit);
        const convertedLegLabelTolerance = convertToleranceUnits({ ...customTolerances.legLabelTolerance }, Units.Millimeter, unit);

        setMinLegLength({ value: convertedLegTolerance.min, unit });
        setMaxLegLength({ value: convertedLegTolerance.max, unit });

        setMinLegPrimeLength({ value: convertedLegPrimeTolerance.min, unit });
        setMaxLegPrimeLength({ value: convertedLegPrimeTolerance.max, unit });

        setMinLegLabelLength({ value: convertedLegLabelTolerance.min, unit });
        setMaxLegLabelLength({ value: convertedLegLabelTolerance.max, unit });
    }, [unit, customTolerances]);

    useEffect(() => {
        if (assembliesLoaded && !hasLoadedAssemblies) {
            setHasLoadedAssemblies(true);

            setInitialMinLegLength({ value: customTolerances.legTolerance.min, unit: Units.Millimeter });
            setInitialMaxLegLength({ value: customTolerances.legTolerance.max, unit: Units.Millimeter });
    
            setInitialMinLegPrimeLength({ value: customTolerances.legPrimeTolerance.min, unit: Units.Millimeter });
            setInitialMaxLegPrimeLength({ value: customTolerances.legPrimeTolerance.max, unit: Units.Millimeter });
    
            setInitialMinLegLabelLength({ value: customTolerances.legLabelTolerance.min, unit: Units.Millimeter });
            setInitialMaxLegLabelLength({ value: customTolerances.legLabelTolerance.max, unit: Units.Millimeter });
        }
    }, [customTolerances, assembliesLoaded, hasLoadedAssemblies]);

    const areTolerancesInvalid = useMemo(() => {
        return minLegLengthField.isValid && maxLegLengthField.isValid && minLegPrimeLengthField.isValid && maxLegPrimeLengthField.isValid && minLegLabelLengthField.isValid && maxLegLabelLengthField.isValid;
    },[minLegLengthField, maxLegLengthField, minLegPrimeLengthField, maxLegPrimeLengthField, minLegLabelLengthField, maxLegLabelLengthField])

    const areTolerancesModified = useMemo(() => {
        const convertedLegTolerance = customTolerances.legTolerance;
        const convertedLegPrimeTolerance = customTolerances.legPrimeTolerance;
        const convertedLegLabelTolerance = customTolerances.legLabelTolerance;

        const minLegLengthFieldModified = convertedLegTolerance.min !== initialMinLegLength.value;
        const maxLegLengthFieldModified = convertedLegTolerance.max !== initialMaxLegLength.value;

        const minLegPrimeLengthFieldModified = convertedLegPrimeTolerance.min !== initialMinLegPrimeLength.value;
        const maxLegPrimeLengthFieldModified = convertedLegPrimeTolerance.max !== initialMaxLegPrimeLength.value;
    
        const minLegLabelLengthFieldModified = convertedLegLabelTolerance.min !== initialMinLegLabelLength.value;
        const maxLegLabelLengthFieldModified = convertedLegLabelTolerance.max !== initialMaxLegLabelLength.value;
        
        return minLegLengthFieldModified || maxLegLengthFieldModified || minLegPrimeLengthFieldModified || maxLegPrimeLengthFieldModified || minLegLabelLengthFieldModified || maxLegLabelLengthFieldModified;
    },[customTolerances, initialMinLegLength, initialMaxLegLength, initialMinLegPrimeLength, initialMaxLegPrimeLength, initialMinLegLabelLength, initialMaxLegLabelLength]);

    const toleranceStates = useMemo(() => {
        const legTolerance = convertToleranceUnits({ min: minLegLength.value, max: maxLegLength.value }, unit, Units.Millimeter);
        const legPrimeTolerance = convertToleranceUnits({ min: minLegPrimeLength.value, max: maxLegPrimeLength.value }, unit, Units.Millimeter);
        const legLabelTolerance = convertToleranceUnits({ min: minLegLabelLength.value, max: maxLegLabelLength.value }, unit, Units.Millimeter);

        return { legTolerance, legPrimeTolerance, legLabelTolerance };
    },[minLegLength, maxLegLength, minLegPrimeLength, maxLegPrimeLength, minLegLabelLength, maxLegLabelLength, unit])
    
    const updateInitialTolerances = useCallback(() => {
        setInitialMinLegLength({ value: customTolerances.legTolerance.min, unit: Units.Millimeter });
        setInitialMaxLegLength({ value: customTolerances.legTolerance.max, unit: Units.Millimeter });

        setInitialMinLegPrimeLength({ value: customTolerances.legPrimeTolerance.min, unit: Units.Millimeter });
        setInitialMaxLegPrimeLength({ value: customTolerances.legPrimeTolerance.max, unit: Units.Millimeter });

        setInitialMinLegLabelLength({ value: customTolerances.legLabelTolerance.min, unit: Units.Millimeter });
        setInitialMaxLegLabelLength({ value: customTolerances.legLabelTolerance.max, unit: Units.Millimeter });
    }, [customTolerances]);

    return { 
        areTolerancesInvalid,
        areTolerancesModified,
        resetTolerances,
        updateInitialTolerances,
        toleranceStates,
        minLegLengthFieldProps, 
        maxLegLengthFieldProps,
        minLegPrimeLengthFieldProps,
        maxLegPrimeLengthFieldProps,
        minLegLabelLengthFieldProps,
        maxLegLabelLengthFieldProps, 
    };
}

const getConnectorsWithBootColors = (connectors: IConnectorWithPositions[], currentBootColor: BootColorCombination) => {
    const connectorsWithBootColor: IConnectorWithPositions[] = [];
    for (const connector of connectors) {
        if (isDuplex(connector.type)) {
            connectorsWithBootColor.push({ ...connector, bootColors: getBootColorCombination(currentBootColor) });
        } else {
            connectorsWithBootColor.push({ ...connector });
        }
    }
    return connectorsWithBootColor;
};

export const useBootColors = () => {
    const currentAssemblyBootColors = useSelector(currentAssemblyBootColorCombinationSelector);
    const hasMultiBootConnectors = useSelector(currentAssemblyHasMultiBootConnectorsSelector);
    const hasLCDuplexConnectors = useSelector(currentAssemblyHasLCDuplexConnectorsSelector);
    const hasSCDuplexConnectors = useSelector(currentAssemblyHasSCDuplexConnectorsSelector);
    const currentAssemblyConnectors = useSelector(currentAssemblyConnectorsSelector);
    const assembliesLoaded = useSelector(assembliesLoadedSelector);
    const [assembliesLoadedCopy, setAssembliesLoadedCopy] = useState(true);
    const [bootColor, setBootColor] = useState(currentAssemblyBootColors);
    const [initialBootColor, setInitialBootColor] = useState(currentAssemblyBootColors);
    const [changedBootColor, setChangedBootColor] = useState(false);
    const { t } = useTranslation();
    const combinations = Object.values(BootColorCombinations);
    
    const bootColorLabel = useMemo(() => {
        if (hasLCDuplexConnectors && !hasSCDuplexConnectors) {
            return t(LocalizationKeys.LCDuplexBootColor);
        } else if (!hasLCDuplexConnectors && hasSCDuplexConnectors) {
            return t(LocalizationKeys.SCDuplexBootColor);
        } else {
            return t(LocalizationKeys.DuplexBootColor);
        }
    }, [t, hasLCDuplexConnectors, hasSCDuplexConnectors]);
    
    const { label, value, options } = useSelectInput(combinations, bootColorLabel, "", bootColor);
    const dispatch = useStoreDispatch();

    const updateConnectorColors = useCallback((newBootColor: BootColorCombination) => {
        const sideAConnectorsWithPositions = getConnectorsWithBootColors(currentAssemblyConnectors.sideAConnectorsWithPositions, newBootColor);
        const sideBConnectorsWithPositions = getConnectorsWithBootColors(currentAssemblyConnectors.sideBConnectorsWithPositions, newBootColor);
        const connectorsWithPositionsToUpdate = [...sideAConnectorsWithPositions, ...sideBConnectorsWithPositions];
        dispatch(setConnectors(connectorsWithPositionsToUpdate));
    },[dispatch, currentAssemblyConnectors]);

    const persistBootColors = useCallback(() => {
        const sideAConnectorsWithPositions = getConnectorsWithBootColors(currentAssemblyConnectors.sideAConnectorsWithPositions, bootColor);
        const sideBConnectorsWithPositions = getConnectorsWithBootColors(currentAssemblyConnectors.sideBConnectorsWithPositions, bootColor);
        const connectorsWithPositionsToUpdate = [...sideAConnectorsWithPositions, ...sideBConnectorsWithPositions];
        dispatch(updateConnectors(connectorsWithPositionsToUpdate));
    }, [dispatch, bootColor, currentAssemblyConnectors]);

    const resetBootColors = useCallback(() => {
        setBootColor(initialBootColor);
        setChangedBootColor(false);
        updateConnectorColors(initialBootColor);
    },[initialBootColor, updateConnectorColors]);

    const updateInitialBootColor = useCallback(() => {
        setInitialBootColor(bootColor);
        setChangedBootColor(false);
    }, [bootColor]);

    useEffect(() => {
        if (assembliesLoaded === assembliesLoadedCopy) {
            setInitialBootColor(currentAssemblyBootColors);
            setBootColor(currentAssemblyBootColors);
            setChangedBootColor(false);
            setAssembliesLoadedCopy(false);
        }
        if (!hasMultiBootConnectors) {
            setBootColor(BootColorCombinations.WhiteWhite);
            setInitialBootColor(BootColorCombinations.WhiteWhite);
            setChangedBootColor(false);
        }
    },[hasMultiBootConnectors, assembliesLoaded, assembliesLoadedCopy, currentAssemblyBootColors])

    const onBootColorChange = useCallback((e) => {
        const newBootColor = e.target.value;
        setBootColor(newBootColor);
        setChangedBootColor(initialBootColor !== newBootColor);
        updateConnectorColors(newBootColor);
    }, [updateConnectorColors, initialBootColor]);

    return {
        bootColorModified: changedBootColor,
        hasDuplexConnectors: hasMultiBootConnectors,
        updateConnectorColors,
        resetBootColors,
        updateInitialBootColor,
        persistBootColors,
        bootColor: {
            label,
            select: {
                variant: "filled" as "filled",
                value,
                onChange: onBootColorChange,
            },
            options
        } 
    }
}