import React from 'react';
import { useAssemblyEditDialog } from './hooks';
import { Dialog } from '@orbit/dialog';
import { Typography } from '@orbit/theme-provider';
import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from '@mui/material';
import { FilledTextField } from '@orbit/text-field';
import { IconButton } from '@orbit/icon-button';
import { Palette } from '@mui/icons-material';
import { ColorDialog } from '../../../ui/dialog/color/ColorDialog';
import { LocalizationKeys } from '../../../localization/types';
import './AssemblyEditDialog.scss';
import { OverallLengthTypeSelect } from '../../../ui/select/overall-length-type/OverallLengthTypeSelect';

export const AssemblyEditDialog: React.FC = () => {
    const {
        t,
        dialogProps,
        pullingGrip,
        bootColor,
        jacket,
        overallLength,
        endA,
        endB,
        outerDiameter,
        colorDialogProps,
        minLegLengthFieldProps,
        maxLegLengthFieldProps,
        minLegPrimeLengthFieldProps,
        maxLegPrimeLengthFieldProps,
        minLegLabelLengthFieldProps,
        maxLegLabelLengthFieldProps, 
        hasDuplexConnectors,
        overallLengthType,
    } = useAssemblyEditDialog();
    if (!dialogProps.open) {
        return null;
    }

    return (
        <Dialog {...dialogProps}>
            <div className='fields-wrapper'>
                <div className='general-fields-container'>
                    <Typography variant='subtitle1' label={t(LocalizationKeys.General)}/>
                    <div className="field-container">
                        <FormControl className='select'>
                            <InputLabel>{pullingGrip.label}</InputLabel>
                            <Select {...pullingGrip.select}>
                                {pullingGrip.options.map((o, i) => <MenuItem key={`pulling-grips-${i}`} value={o}>{o}</MenuItem>)}
                            </Select>
                            <FormHelperText>{t(LocalizationKeys.PullingGripHelp)}</FormHelperText>
                        </FormControl>
                    </div>
                    <div className="field-container">
                        <FilledTextField {...jacket.field} InputProps={{ endAdornment: <IconButton {...jacket.icon} onClick={jacket.onClick} icon={<Palette />} />, readOnly: true }} />
                    </div>
                    <div className="field-container">
                        <OverallLengthTypeSelect overallLengthType={overallLengthType}/>
                    </div>
                    <div className="field-container">
                        <FormControl>
                            <FilledTextField {...overallLength} />
                            <FormHelperText>{overallLength.error ? '' : t(LocalizationKeys.OverallLengthHelp)}</FormHelperText>
                        </FormControl>
                    </div>
                    <div className="field-container">
                        <FilledTextField {...endA} />
                    </div>
                    <div className="field-container">
                        <FilledTextField {...endB} />
                    </div>
                    { hasDuplexConnectors ? 
                        <div className="field-container">
                            <FormControl className='select'>
                                <InputLabel>{bootColor.label}</InputLabel>
                                <Select {...bootColor.select}>
                                    { bootColor.options.map((o, i) => <MenuItem key={`boot-colors-${i}`} value={o}>{o}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </div>
                        : null
                    }
                    <div className="field-container">
                        <FormControl className='select'>
                            <InputLabel>{outerDiameter.label}</InputLabel>
                            <Select {...outerDiameter.select}>
                                { outerDiameter.options.map((o, i) => <MenuItem key={`outer-diameters-${i}`} value={o}>{o}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="tolerances-container">
                    <Typography variant='subtitle1' label={t(LocalizationKeys.Tolerances)} />
                    <div className="leg-length-tolerances">
                        <Typography variant='body2' label={t(LocalizationKeys.LegTolerance)} />
                        <div className="fields-container">
                            <div className="field-container">
                                <FormControl>
                                    <FilledTextField {...minLegLengthFieldProps} />
                                </FormControl>
                            </div>
                            <div className="tolerance-separator">/</div>
                            <div className="field-container">
                                <FilledTextField {...maxLegLengthFieldProps}/>
                            </div>
                        </div>
                    </div>

                    <div className="leg-prime-length-tolerances">
                        <Typography variant='body2' label={t(LocalizationKeys.LegPrimeTolerance)}/>
                        <div className="fields-container with-help">
                            <div className="field-container">
                                <FormControl>
                                    <FilledTextField {...minLegPrimeLengthFieldProps} />
                                </FormControl>
                            </div>
                            <div className="tolerance-separator">/</div>
                            <div className="field-container">
                                <FilledTextField {...maxLegPrimeLengthFieldProps}/>
                            </div>
                        </div>
                    </div>

                    <div className="leg-label-length-tolerances">
                        <Typography variant='body2' label={t(LocalizationKeys.LabelTolerance)}/>
                        <div className="fields-container with-help">
                            <div className="field-container">
                                <FormControl>
                                    <FilledTextField {...minLegLabelLengthFieldProps} />
                                </FormControl>
                            </div>
                            <div className="tolerance-separator">/</div>
                            <div className="field-container">
                                <FilledTextField {...maxLegLabelLengthFieldProps}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ColorDialog {...colorDialogProps} />
        </Dialog>
    );
}