import { createSlice } from "@reduxjs/toolkit";
import { setCableEnvironmentAction, setFiberTypeAction, setFiberCountAction, setShapeAction, setOverallLengthAction, setLengthAAction, setLengthBAction, setGeneralSetupStateAction, convertLengthUnitsAction, setFlameRatingAction, setPullingGripAction, setCableTypeAction, setFurcationOuterDiameter, setOverallLengthTypeAction } from "./actions";
import { initialGeneralSetupState } from "./types";

const generalSetupSlice = createSlice({
    name: "general-setup",
    initialState: initialGeneralSetupState,
    reducers: {
        setShape: setShapeAction,
        setFiberCount: setFiberCountAction,
        setCableType: setCableTypeAction,
        setFiberType: setFiberTypeAction,
        setCableEnvironment: setCableEnvironmentAction,
        setOverallLength: setOverallLengthAction,
        setLengthA: setLengthAAction,
        setLengthB: setLengthBAction,
        setGeneralSetupState: setGeneralSetupStateAction,
        convertGeneralLengthUnits: convertLengthUnitsAction,
        setFlameRating: setFlameRatingAction,
        setPullingGrip: setPullingGripAction,
        setOuterDiameter: setFurcationOuterDiameter,
        setOverallLengthType: setOverallLengthTypeAction
    }
})

export const GeneralSetupReducer = generalSetupSlice.reducer;

export const GeneralSetupActions = generalSetupSlice.actions;

export const {
    setShape,
    setFiberCount,
    setCableType,
    setFiberType,
    setCableEnvironment,
    setOverallLength,
    setLengthA,
    setLengthB,
    setGeneralSetupState,
    convertGeneralLengthUnits,
    setFlameRating,
    setPullingGrip,
    setOuterDiameter,
    setOverallLengthType
} = generalSetupSlice.actions;

